<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				class="width-500 ma-auto bg-white radius-20 full-height flex-column"
			>
				<div
					class="size-px-20 text-center font-weight-bold under-line pb-20 
					bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20 position-relative"
					>
					<h4 class="color-white">
						{{ $language.cartel_new.title_support_history_detail }} <!-- 후원 상세 -->
					</h4>
					<div class="popup-close">
						<img class="cursor-pointer"	@click="$emit('click')"	:src="require('@/assets/image/web/notify_cartel/ico_close.svg')"/>
					</div>
				</div>

				<div
					class="size-px-14 full-height flex-column"
				>
					<div class="pa-30">
						<div class="pb-10">
							<div class="size-px-16 font-weight-bold">{{ $language.point_result.title_information }} <!-- 정보 --></div>
							<div class="mt-10 justify-space-between">
								<span>{{ $language.point.txt_heart }} <!-- 불렛 --></span>
								<span class="font-weight-500">{{ item_info.spnsh_point_quantity | makeComma }}</span>
							</div>
							<div class="mt-10 justify-space-between">
								<span>{{ $language.add.title_sponsor}} <!-- 후원자 --></span>
								<span class="font-weight-500">{{ item_info.spnsh_member_nickname }}</span>
							</div>
							<div class="mt-10 justify-space-between">
								<span>{{ $language.point_result.title_date }} <!-- 일시 --></span>
								<span class="font-weight-500">{{ item_info.spnshday }}</span>
							</div>
						</div>
						<div class="mt-20 pt-20 top-line">
							<div class="size-px-16 font-weight-bold">{{ $language.add.title_support_message }} <!-- 응원 메시지 --></div>
							<div class="mt-10 " :inner-html.prop="item_info.spnsh_message | nl2br">
							</div>
						</div>

						<div
						class="text-center"
						>
							<button
								class="btn-primary pa-13-30 size-px-14 font-weight-500 radius-20 line-height-1"
								style="outline: none;"
								@click="$emit('click')"
							>{{ $language.point_result.btn_confirm }} <!-- 확인 --></button>
						</div>
					</div>

				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'CartelSupportHistoryDetail'
	, components: {PopupLayer}
	, props: ['user', 'item_info']
	, data: function(){
		return {

		}
	}
	, methods: {

	}
	, created() {
		console.log(this.item_info)
	}
}
</script>