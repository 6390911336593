<template>
	<div class="flex-1 bg-white pa-24 radius-20">

		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">{{  program.title }}</h3>
		</div>
		<div class="full-height flex-column">
			<div
				class="pa-10"
			>
				<div class="pa-20">
					<DatePicker class="mt-20" type="year" :date="search_year" @click="setDate($event)" ></DatePicker>
				</div>

				<div>
					<table
						v-if="items.length > 0"
						class="table"
					>
						<colgroup>
							<col width="120px" />
							<col width="120px" />
							<col width="auto" />
							<col width="180px" />
						</colgroup>
						<thead>
							<tr>
								<th>불렛</th>
								<th>후원자</th>
								<th>응원</th>
								<th>일시</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(history, h_index) in list_history"
								:key="'history_' + h_index"
								class="under-line-not-last pb-20 mb-20"
								@click="onDetail(history)"
							>
								<td>
									<div class="size-px-16 font-weight-bold">{{ history.spnsh_point_quantity | makeComma }} {{ $language.point.txt_heart }}</div>
								</td>
								<td>
									<div
									>{{ history.spnsh_member_nickname }}</div>
								</td>
								<td>
									<div class="text-left">{{ history.spnsh_message | maxLength(20, '...')}}</div>
								</td>
								<td>
									<div>{{ history.spnshday }}</div>
								</td>
							</tr>
						</tbody>
					</table>
					<div
						v-else
						class="character3_none pt-130 text-center size-px-16 color-bbb"
					>후원 내역이 없습니다.</div>
				</div>
			</div>
		</div>

		<CartelSupportHistoryDetail
			v-if="is_on_detail"
			:user="user"
			:item_info="item_detail"

			@click="offDetail"
			@cancel="offDetail"
		></CartelSupportHistoryDetail>
	</div>
</template>

<script>

import DatePicker from "@/components/DatePicker";
import CartelSupportHistoryDetail from "@/view/Cartel/CartelSupportHistoryDetail";
export default {
	name: 'CartelSupportHistory'
	, components: {CartelSupportHistoryDetail, DatePicker}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '후원 내역'
				, title: '후원 내역'
				, type: 'cartel_sub'
				, not_header: true
				, not_footer: true
				, bg_contents: 'bg-gray01'
				, is_side: true
				, cartel: ''
			}
			, items: [
			]
			, is_on_detail: false
			, item_detail: {

			}
			, search_year: this.$date.init(this.$date.getToday('-'), '-').year
			, item_search: {
				page: 1
				, list_cnt: 10
			}
		}
	}
	, computed: {
		list_history: function(){
			return this.items.filter( (item) => {
				switch (item.payment_state_code){
					case 'PM00400007':
						item.payment_state_color = 'color-red'
						break;
				}

				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_user_support_history
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, search_year: this.search_year + ''
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})

				if(result.success){
					if(this.items.length > 0){
						this.items = this.items.concat(result.data.spnsh_particulars_list)
					}else{
						this.items = result.data.spnsh_particulars_list
					}
					let page_info = result.data.page_info
					if(this.items.length < page_info.record_tot_count){
						this.item_search.page++
						await this.getData()
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onDetail: async function(item){
			this.item_detail = item
			this.is_on_detail = true
		}
		, offDetail: function(){
			this.is_on_detail = false
			this.item_detail = {}
		}
		, to: function(type){
			this.$bus.$emit('to', { name: 'PaymentHistory', params: { type: type}})
		}
		, setDate: function(date){
			this.search_year = date
			this.getData()
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>
